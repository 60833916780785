<template>
  <div>
    <h1>hello i18n</h1>
    <h3>{{ $t("message") }}</h3>
    <p>{{ $t("hello") }}</p>
    <div class="locale-changer">
      <select v-model="locale">
        <option
          v-for="(lang, i) in langs"
          :key="`Lang${i}`"
          :value="lang.split('-')[0]"
          >{{ lang }}</option
        >
      </select>
    </div>
  </div>
</template>

<script>
import { validLanguages } from "@/setup/i18n";

export default {
  name: "HelloI18n",
  metaInfo() {
    return {
      title: this.$t("hello")
    };
  },
  data() {
    return {
      langs: validLanguages
    };
  },
  computed: {
    locale: {
      get() {
        return this.$i18n.locale;
      },
      set(val) {
        const rn = this.$route.name;
        this.$router.push({
          name: rn.endsWith("-lang") ? rn : `${rn}-lang`,
          params: { lang: val.split("-")[0] }
        });
      }
    }
  },
  methods: {}
};
</script>
